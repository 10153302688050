<template>
  <div class="register_content" :class="{'showLoginPannel':$store.state.isShowAgentCodePanel && !$store.state.isLogin}">
    <div class="agent_code_panel" style="position: relative">
      <div @click="goback"
          style="width:3rem;height:3rem;display: flex;align-items: center;justify-content: center;position: absolute;top:.5rem;right: .5rem;cursor: pointer">
        <img src="../../assets/images/icon/common/close1.svg" style="width: 3rem"/>
      </div>
      <div style="width: 100%;display: flex;align-items: center;justify-content: center;">
        <img src="../../assets/images/logo/ptn/logo01.png" alt="" style="height: 70px">
      </div>
      <div style="margin-top: 1rem;display: flex;align-items: center;justify-content: space-between;position: relative">

        <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: nowrap;width: 100%">
          <span style="color: #252525" @keyup.enter="checkCode">추천인코드</span>
          <div>
            <input type="text" v-model="$store.state.userinsertagentcode">
          </div>
          <button @click="checkCode">확인</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {loginRegisterMixin} from "../../common/mixin";
import WarningSample from "../../components/WarningSample";
import MemberLoginSection from "@/views/afront/MemberLoginSection.vue";
import MemberRegisterSection from "@/views/afront/MemberRegisterSection.vue";
import {checkAgentCode} from "@/network/userRequest";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

export default {
  name: "MemberAgentCodeWindow",
  components: {
    MemberRegisterSection
  },
  data() {
    return {
      user: {
        username: '',
        passwd: '',
        hvcode: null,
      },
    }
  },
  methods: {
    showRegisterPanel() {
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = true
        this.$store.state.isShowLoginPanel = false
        this.$store.state.isShowAgentCodePanel = false
      }
    },
    goback() {
      this.$store.state.userinsertagentcode = ''
      this.$store.state.isShowRegisterPanel = false
      this.$store.state.isShowLoginPanel = false
      this.$store.state.isShowAgentCodePanel = false
    },
    checkCode() {
      if (this.$store.state.userinsertagentcode.trim() === '') {
        this.$swal({
          title: '추천인 코드를 입력하세요',
          type: 'warning',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      this.$store.commit(RECEIVE_SHOW_LOADING);
      checkAgentCode({code: this.$store.state.userinsertagentcode}).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING);
        this.$store.state.ischeckedagentcode = true;
        if (res.data.success) {
          this.showRegisterPanel()
        } else {
          this.$swal({
            title: '추천인 코드를 정확하게 입력하세요',
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })

    }
  },
  created() {
  },
  computed: {}
}
</script>

<style scoped>
.register_content {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transform: translateY(-100%);
  transition: 0.3s transform;
  background-color: rgba(0, 0, 0, .7);
}

.agent_code_panel {
  width: 36rem;
  height: 20rem;
  background-color: #dadada;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.agent_code_panel input {

  height: 2.8rem;
  border-radius: 1px;
  background-color: #ffffff;
  text-align: center;
  color: #353535;
  margin: 0 .5rem;
}

.agent_code_panel button {
  background-color: #f31616;
  height: 2.8rem;
  line-height: 28px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 2px;
  width: 50px;
}

.showLoginPannel {
  transform: translateY(0%);
}


</style>
