<template>
  <div class="overlay"
       :class="{'showNotMemberCustomerPannel':$store.state.isShowNotMemberCustomerePanel && !$store.state.isLogin}">

    <div class="overlay-warp">
      <div class="overlay-open">
        <div class="register-warp" style="position: relative">
          <div
              style="position: absolute;top:10px;right: 10px;background: red;z-index: 9999;border-radius: 5px;padding: 5px;cursor: pointer"
              @click="closePanel">
            <i class="fa fa-window-close" style="color: #ffffff;font-size: 16px"></i>
          </div>
          <div class="login-head">
            <h4>
              <img src="../../../assets/images/logo/ptn/logo01.png" style="height: 50px"/>
            </h4>
          </div>
          <div style="padding: 5px">
            <div style="border: 1px solid #f0f0f0;border-radius: 5px;padding: 10px">
              문의내용 <font style="color: #f1ae17">(아이디,연락가능 전화번호를 남겨주세요)</font>
              <textarea v-model="content"
                        style="border: 1px solid #6f6f6f;background-color: #898989;outline: transparent;margin-top: 5px;border-radius: 5px"></textarea>
            </div>

            <div style="display: flex;justify-content: center;align-items: center;margin-top: 10px">
              <div style="display: flex;justify-content: center;align-items: center;margin-top: 10px">
                <img :src="imageSrc" alt="" style="width: 128px;margin-right: 3px" @click="refreshHVcode">
                <input type="text" v-model="hvcode" placeholder="로봇방지코드"
                       style="height: 40px;text-align: center;width: 120px;color: #010101;margin-right: 3px">
                <button style="width: 130px;background-color: #f1ae17;height: 40px;color: #fff9ff"
                        @click="saveNotMemberQuestion">문의하기
                </button>
              </div>

            </div>
          </div>

          <div v-for="(item,idex) in cusList"
               style="border-radius: 5px;border: 1px solid #ff973e;padding: 10px;margin-top: 10px">
            <p style="line-height: 38px;padding: 10px;"><span v-html="item.content"></span></p>
            <div v-html="item.contentReply" style="background-color: #555555;padding: 5px;border-radius: 5px">
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {loginRegisterMixin} from "@/common/mixin";
import {getnotmemberanswer, saveNotMemberQuestion} from "@/network/userRequest";
import LoadingLoginComp from "@/components/LoadingLoginComp";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

export default {
  name: "NotMemeberCustomerComp",
  components: {LoadingLoginComp},
  mixins: [loginRegisterMixin],
  data() {
    return {
      content: '',
      cusList: null,
      hvcode: '',
      imageSrc: ''
    }
  },
  methods: {
    closePanel() {
      this.$store.state.isShowLoginPanel = false;
      this.$store.state.isShowRegisterPanel = false;
      this.$store.state.isShowNotMemberCustomerePanel = false;
      this.content = ''

    },
    saveNotMemberQuestion() { //회원가입시 아이디 체크
      if (this.content == '') {
        this.$swal({
          title: '내용을 입력하세요',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      if (this.hvcode == '') {
        this.$swal({
          title: '로봇방지코드를 입력하세요',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      this.$store.commit(RECEIVE_SHOW_LOADING)
      saveNotMemberQuestion({'content': this.content}).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        this.$store.state.isShowLoginPanel = false;
        this.$store.state.isShowRegisterPanel = false;
        this.$store.state.isShowNotMemberCustomerePanel = false;
        this.content = ''
        if (res.data.success) {
          this.content = ''
          this.$swal({
            title: '비회원 문의글 작성이 완료되였습니다',
            type: 'success',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    getHVCode() {
      switch (process.env.NODE_ENV) {
        case 'development':
          let prot = window.location.protocol
          let domain = window.location.host.replaceAll("8080", '7070')
          this.imageSrc = prot + '//' + domain + '/api/user/hvcode_notloginask';
          break
        case 'production':
          let prot2 = window.location.protocol
          let domain2 = window.location.host
          this.imageSrc = prot2 + '//' + domain2 + '/api/user/hvcode_notloginask';
          break
      }

    },
    refreshHVcode() {
      window.location.reload()
    },

  },
  created() {
    getnotmemberanswer().then(res => {
      this.cusList = res.data.data
      console.log(this.cusList)
    })
  },
  computed: {}
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontuserinfo.css");

@media screen and (max-width: 1024px) {
  .register-warp {
    width: 100% !important;
    height: 100vh !important;
  }

  .overlay .overlay-warp .overlay-open {
    width: 100%;
  }
}

.showNotMemberCustomerPannel {
  transform: translateY(0%);
}

.register-warp {
  width: 750px;
  height: 700px;
  background-color: #303030;
  color: #fff9ff;
  border: 1px solid #1f1f1f;
  overflow-y: scroll;
  border-radius: 5px;
  padding: 10px;
}

.register-warp .login-head {
  background-color: #303030;
  color: #fff;
  height: 100px;
  line-height: 100px;
  padding: 0 5px;
  position: relative;
  text-transform: uppercase;
  width: 100%;
  z-index: 5002;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>